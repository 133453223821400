import { default as _4047Xi4OILrTdMeta } from "/opt/buildhome/repo/pages/404.vue?macro=true";
import { default as infoZ6RaEXK92uMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/[contextId]/index/info.vue?macro=true";
import { default as indexoCbqmu4x1QMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/[contextId]/index.vue?macro=true";
import { default as indexdO8mSdwFuwMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/index.vue?macro=true";
import { default as newi2gEPXuuIJMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/new.vue?macro=true";
import { default as infoNjB9Br8POmMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/info.vue?macro=true";
import { default as _91locationId_93kHcSGMb54bMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/locations/[locationId].vue?macro=true";
import { default as indexgLYkdph7ZXMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/locations/index.vue?macro=true";
import { default as newv7zjRLTAKWMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/locations/new.vue?macro=true";
import { default as infoZfguRY67WIMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/[managerId]/index/info.vue?macro=true";
import { default as indexUd7ZAFtvqhMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/[managerId]/index.vue?macro=true";
import { default as indexYQ0B58XBEhMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/index.vue?macro=true";
import { default as newRzaErz68mGMeta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/new.vue?macro=true";
import { default as indexWzp3TM2Cr5Meta } from "/opt/buildhome/repo/pages/accounts/[accountId]/index.vue?macro=true";
import { default as indexA7vL6YiQurMeta } from "/opt/buildhome/repo/pages/accounts/index.vue?macro=true";
import { default as newNUCD9JQYjRMeta } from "/opt/buildhome/repo/pages/accounts/new.vue?macro=true";
import { default as infopF7dMNBOKiMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/info.vue?macro=true";
import { default as _91locationId_93fdMp8dbXRwMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/locations/[locationId].vue?macro=true";
import { default as indexlJ3e4NYQGWMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/locations/index.vue?macro=true";
import { default as newMqE7I1KSnWMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/locations/new.vue?macro=true";
import { default as _91promoCodeId_939c19w1fHmgMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/promo-codes/[promoCodeId].vue?macro=true";
import { default as indexmX5tBR6fmVMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/promo-codes/index.vue?macro=true";
import { default as newYgfUWiTeZLMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index/promo-codes/new.vue?macro=true";
import { default as indexgULYwzC72sMeta } from "/opt/buildhome/repo/pages/areas/[areaId]/index.vue?macro=true";
import { default as index4McHRRtIZHMeta } from "/opt/buildhome/repo/pages/areas/index.vue?macro=true";
import { default as newFt4xE1ZrL8Meta } from "/opt/buildhome/repo/pages/areas/new.vue?macro=true";
import { default as _91payoutId_93t9TJCuyMfJMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/account-payouts/[payoutId].vue?macro=true";
import { default as indexd1bIEY6VTKMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/account-payouts/index.vue?macro=true";
import { default as newZmUpFFCfaHMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/account-payouts/new.vue?macro=true";
import { default as _91chargeId_93zksFS3oAHCMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/charges/[chargeId].vue?macro=true";
import { default as indexK1ZOEDeZfHMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/charges/index.vue?macro=true";
import { default as newp3B01qc1BrMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/charges/new.vue?macro=true";
import { default as events7Bne9c2thOMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/events.vue?macro=true";
import { default as info6qapfWY3aSMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/info.vue?macro=true";
import { default as _91noteId_93Q4todAnz8FMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/notes/[noteId].vue?macro=true";
import { default as indexKkTRSf6UP8Meta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/notes/index.vue?macro=true";
import { default as newHpC9X6Crm4Meta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/notes/new.vue?macro=true";
import { default as _91paymentId_93Jjf0AnuPuXMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/payments/[paymentId].vue?macro=true";
import { default as indexq7e4wFl3wHMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/payments/index.vue?macro=true";
import { default as newgFTIDLytfDMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/payments/new.vue?macro=true";
import { default as _91payoutId_93ZiirvDWN79Meta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/provider-payouts/[payoutId].vue?macro=true";
import { default as indexA243Phz5nCMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/provider-payouts/index.vue?macro=true";
import { default as newGzJQgLcx3rMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index/provider-payouts/new.vue?macro=true";
import { default as indexbucWoApyliMeta } from "/opt/buildhome/repo/pages/bookings/[bookingId]/index.vue?macro=true";
import { default as indexqVDJglidTwMeta } from "/opt/buildhome/repo/pages/bookings/index.vue?macro=true";
import { default as newKVtEpjGbnOMeta } from "/opt/buildhome/repo/pages/bookings/new.vue?macro=true";
import { default as infoPcoMcUvRjTMeta } from "/opt/buildhome/repo/pages/categories/[categoryId]/index/info.vue?macro=true";
import { default as indexGlGZrlZGdXMeta } from "/opt/buildhome/repo/pages/categories/[categoryId]/index.vue?macro=true";
import { default as index6yDS2Nat0aMeta } from "/opt/buildhome/repo/pages/categories/index.vue?macro=true";
import { default as newz6eM2CXxbUMeta } from "/opt/buildhome/repo/pages/categories/new.vue?macro=true";
import { default as indexroQjwNrhD2Meta } from "/opt/buildhome/repo/pages/charges/index.vue?macro=true";
import { default as account_45payouts13o8EzXFo6Meta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/account-payouts.vue?macro=true";
import { default as additional_45info0jwY0IqdWXMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/additional-info.vue?macro=true";
import { default as bookings9GKtsUPFvQMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings.vue?macro=true";
import { default as charges3n1C30ThmFMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/charges.vue?macro=true";
import { default as infoVrPOUawKlwMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/info.vue?macro=true";
import { default as invoicesXGfEzLkOHDMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/invoices.vue?macro=true";
import { default as paymentscNDVodeJ4JMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/payments.vue?macro=true";
import { default as provider_45payoutscO87HGP7VMMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/provider-payouts.vue?macro=true";
import { default as transferseA2qvnKOfGMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index/transfers.vue?macro=true";
import { default as indexc699YS4snzMeta } from "/opt/buildhome/repo/pages/contexts/[contextId]/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as infol239hkoaiXMeta } from "/opt/buildhome/repo/pages/modalities/[modalityId]/index/info.vue?macro=true";
import { default as indexD5WnwoNjuPMeta } from "/opt/buildhome/repo/pages/modalities/[modalityId]/index.vue?macro=true";
import { default as indexLjd8kOrXwqMeta } from "/opt/buildhome/repo/pages/modalities/index.vue?macro=true";
import { default as newAw6r4JmKOIMeta } from "/opt/buildhome/repo/pages/modalities/new.vue?macro=true";
import { default as indexghywfYsx49Meta } from "/opt/buildhome/repo/pages/payments/index.vue?macro=true";
import { default as indexeFiMOHGC5ZMeta } from "/opt/buildhome/repo/pages/payouts/index.vue?macro=true";
import { default as accountsTh8XRgFp3yMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/accounts.vue?macro=true";
import { default as additional_45infoYWncVB1iSSMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/additional-info.vue?macro=true";
import { default as areasNVxbNTOWkvMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/areas.vue?macro=true";
import { default as imagesbZ6FRJXuDcMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/images.vue?macro=true";
import { default as infouADns46T5kMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/info.vue?macro=true";
import { default as _91locationId_9369dpBSEzYCMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/locations/[locationId].vue?macro=true";
import { default as indexsAzwi88pUiMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/locations/index.vue?macro=true";
import { default as newzVHgDo9tefMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/locations/new.vue?macro=true";
import { default as skills9XpKnZhgPkMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index/skills.vue?macro=true";
import { default as indexSpiKnQKynbMeta } from "/opt/buildhome/repo/pages/services/[serviceId]/index.vue?macro=true";
import { default as indexVrpVjnZSa3Meta } from "/opt/buildhome/repo/pages/services/index.vue?macro=true";
import { default as newA2PMd6BJ7MMeta } from "/opt/buildhome/repo/pages/services/new.vue?macro=true";
import { default as index8vUQxPinJcMeta } from "/opt/buildhome/repo/pages/skills/index.vue?macro=true";
import { default as test_45base_45layer8YDqQuCUASMeta } from "/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/pages/test-base-layer.vue?macro=true";
import { default as accountstSYvsBMx8dMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/accounts.vue?macro=true";
import { default as indexKtvmlmxucPMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/bookings/index.vue?macro=true";
import { default as news57jRetNSKMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/bookings/new.vue?macro=true";
import { default as indexwNlZW86lOPMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/credit-cards/index.vue?macro=true";
import { default as newIZFH8sD2weMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/credit-cards/new.vue?macro=true";
import { default as infoWOHTNf4odgMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/info.vue?macro=true";
import { default as _91locationId_93MRdOlGCQtHMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/locations/[locationId].vue?macro=true";
import { default as indexJnc72xAc5EMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/locations/index.vue?macro=true";
import { default as newx0Yuh9MFtPMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/locations/new.vue?macro=true";
import { default as paymentsPjPv5OlOXQMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/payments.vue?macro=true";
import { default as _91payoutId_93XQluNUkPAIMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/payouts/[payoutId].vue?macro=true";
import { default as indexd0NM9hTZ1PMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/payouts/index.vue?macro=true";
import { default as new300mPj5fATMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/payouts/new.vue?macro=true";
import { default as providersTMNbEavGVSMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/providers.vue?macro=true";
import { default as skills247uGjBtKzMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/skills.vue?macro=true";
import { default as transfersAFO7htne7MMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/transfers.vue?macro=true";
import { default as zipcodes8r1z26taSDMeta } from "/opt/buildhome/repo/pages/users/[userId]/index/zipcodes.vue?macro=true";
import { default as index9vYc50yRfeMeta } from "/opt/buildhome/repo/pages/users/[userId]/index.vue?macro=true";
import { default as indexxwOGCwGhjWMeta } from "/opt/buildhome/repo/pages/users/index.vue?macro=true";
import { default as _91role_93EuOJDzRqbSMeta } from "/opt/buildhome/repo/pages/users/new/[role].vue?macro=true";
export default [
  {
    name: _4047Xi4OILrTdMeta?.name ?? "404",
    path: _4047Xi4OILrTdMeta?.path ?? "/404",
    meta: _4047Xi4OILrTdMeta || {},
    alias: _4047Xi4OILrTdMeta?.alias || [],
    redirect: _4047Xi4OILrTdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: indexWzp3TM2Cr5Meta?.name ?? "accounts-accountId",
    path: indexWzp3TM2Cr5Meta?.path ?? "/accounts/:accountId()",
    meta: indexWzp3TM2Cr5Meta || {},
    alias: indexWzp3TM2Cr5Meta?.alias || [],
    redirect: indexWzp3TM2Cr5Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexoCbqmu4x1QMeta?.name ?? "accounts-accountId-index-contexts-contextId",
    path: indexoCbqmu4x1QMeta?.path ?? "contexts/:contextId()",
    meta: indexoCbqmu4x1QMeta || {},
    alias: indexoCbqmu4x1QMeta?.alias || [],
    redirect: indexoCbqmu4x1QMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/[contextId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: infoZ6RaEXK92uMeta?.name ?? "accounts-accountId-index-contexts-contextId-index-info",
    path: infoZ6RaEXK92uMeta?.path ?? "info",
    meta: infoZ6RaEXK92uMeta || {},
    alias: infoZ6RaEXK92uMeta?.alias || [],
    redirect: infoZ6RaEXK92uMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/[contextId]/index/info.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexdO8mSdwFuwMeta?.name ?? "accounts-accountId-index-contexts",
    path: indexdO8mSdwFuwMeta?.path ?? "contexts",
    meta: indexdO8mSdwFuwMeta || {},
    alias: indexdO8mSdwFuwMeta?.alias || [],
    redirect: indexdO8mSdwFuwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/index.vue").then(m => m.default || m)
  },
  {
    name: newi2gEPXuuIJMeta?.name ?? "accounts-accountId-index-contexts-new",
    path: newi2gEPXuuIJMeta?.path ?? "contexts/new",
    meta: newi2gEPXuuIJMeta || {},
    alias: newi2gEPXuuIJMeta?.alias || [],
    redirect: newi2gEPXuuIJMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/contexts/new.vue").then(m => m.default || m)
  },
  {
    name: infoNjB9Br8POmMeta?.name ?? "accounts-accountId-index-info",
    path: infoNjB9Br8POmMeta?.path ?? "info",
    meta: infoNjB9Br8POmMeta || {},
    alias: infoNjB9Br8POmMeta?.alias || [],
    redirect: infoNjB9Br8POmMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/info.vue").then(m => m.default || m)
  },
  {
    name: _91locationId_93kHcSGMb54bMeta?.name ?? "accounts-accountId-index-locations-locationId",
    path: _91locationId_93kHcSGMb54bMeta?.path ?? "locations/:locationId()",
    meta: _91locationId_93kHcSGMb54bMeta || {},
    alias: _91locationId_93kHcSGMb54bMeta?.alias || [],
    redirect: _91locationId_93kHcSGMb54bMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: indexgLYkdph7ZXMeta?.name ?? "accounts-accountId-index-locations",
    path: indexgLYkdph7ZXMeta?.path ?? "locations",
    meta: indexgLYkdph7ZXMeta || {},
    alias: indexgLYkdph7ZXMeta?.alias || [],
    redirect: indexgLYkdph7ZXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/locations/index.vue").then(m => m.default || m)
  },
  {
    name: newv7zjRLTAKWMeta?.name ?? "accounts-accountId-index-locations-new",
    path: newv7zjRLTAKWMeta?.path ?? "locations/new",
    meta: newv7zjRLTAKWMeta || {},
    alias: newv7zjRLTAKWMeta?.alias || [],
    redirect: newv7zjRLTAKWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/locations/new.vue").then(m => m.default || m)
  },
  {
    name: indexUd7ZAFtvqhMeta?.name ?? "accounts-accountId-index-managers-managerId",
    path: indexUd7ZAFtvqhMeta?.path ?? "managers/:managerId()",
    meta: indexUd7ZAFtvqhMeta || {},
    alias: indexUd7ZAFtvqhMeta?.alias || [],
    redirect: indexUd7ZAFtvqhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/[managerId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: infoZfguRY67WIMeta?.name ?? "accounts-accountId-index-managers-managerId-index-info",
    path: infoZfguRY67WIMeta?.path ?? "info",
    meta: infoZfguRY67WIMeta || {},
    alias: infoZfguRY67WIMeta?.alias || [],
    redirect: infoZfguRY67WIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/[managerId]/index/info.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexYQ0B58XBEhMeta?.name ?? "accounts-accountId-index-managers",
    path: indexYQ0B58XBEhMeta?.path ?? "managers",
    meta: indexYQ0B58XBEhMeta || {},
    alias: indexYQ0B58XBEhMeta?.alias || [],
    redirect: indexYQ0B58XBEhMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/index.vue").then(m => m.default || m)
  },
  {
    name: newRzaErz68mGMeta?.name ?? "accounts-accountId-index-managers-new",
    path: newRzaErz68mGMeta?.path ?? "managers/new",
    meta: newRzaErz68mGMeta || {},
    alias: newRzaErz68mGMeta?.alias || [],
    redirect: newRzaErz68mGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/[accountId]/index/managers/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexA7vL6YiQurMeta?.name ?? "accounts",
    path: indexA7vL6YiQurMeta?.path ?? "/accounts",
    meta: indexA7vL6YiQurMeta || {},
    alias: indexA7vL6YiQurMeta?.alias || [],
    redirect: indexA7vL6YiQurMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: newNUCD9JQYjRMeta?.name ?? "accounts-new",
    path: newNUCD9JQYjRMeta?.path ?? "/accounts/new",
    meta: newNUCD9JQYjRMeta || {},
    alias: newNUCD9JQYjRMeta?.alias || [],
    redirect: newNUCD9JQYjRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/accounts/new.vue").then(m => m.default || m)
  },
  {
    name: indexgULYwzC72sMeta?.name ?? "areas-areaId",
    path: indexgULYwzC72sMeta?.path ?? "/areas/:areaId()",
    meta: indexgULYwzC72sMeta || {},
    alias: indexgULYwzC72sMeta?.alias || [],
    redirect: indexgULYwzC72sMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: infopF7dMNBOKiMeta?.name ?? "areas-areaId-index-info",
    path: infopF7dMNBOKiMeta?.path ?? "info",
    meta: infopF7dMNBOKiMeta || {},
    alias: infopF7dMNBOKiMeta?.alias || [],
    redirect: infopF7dMNBOKiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/info.vue").then(m => m.default || m)
  },
  {
    name: _91locationId_93fdMp8dbXRwMeta?.name ?? "areas-areaId-index-locations-locationId",
    path: _91locationId_93fdMp8dbXRwMeta?.path ?? "locations/:locationId()",
    meta: _91locationId_93fdMp8dbXRwMeta || {},
    alias: _91locationId_93fdMp8dbXRwMeta?.alias || [],
    redirect: _91locationId_93fdMp8dbXRwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: indexlJ3e4NYQGWMeta?.name ?? "areas-areaId-index-locations",
    path: indexlJ3e4NYQGWMeta?.path ?? "locations",
    meta: indexlJ3e4NYQGWMeta || {},
    alias: indexlJ3e4NYQGWMeta?.alias || [],
    redirect: indexlJ3e4NYQGWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/locations/index.vue").then(m => m.default || m)
  },
  {
    name: newMqE7I1KSnWMeta?.name ?? "areas-areaId-index-locations-new",
    path: newMqE7I1KSnWMeta?.path ?? "locations/new",
    meta: newMqE7I1KSnWMeta || {},
    alias: newMqE7I1KSnWMeta?.alias || [],
    redirect: newMqE7I1KSnWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/locations/new.vue").then(m => m.default || m)
  },
  {
    name: _91promoCodeId_939c19w1fHmgMeta?.name ?? "areas-areaId-index-promo-codes-promoCodeId",
    path: _91promoCodeId_939c19w1fHmgMeta?.path ?? "promo-codes/:promoCodeId()",
    meta: _91promoCodeId_939c19w1fHmgMeta || {},
    alias: _91promoCodeId_939c19w1fHmgMeta?.alias || [],
    redirect: _91promoCodeId_939c19w1fHmgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/promo-codes/[promoCodeId].vue").then(m => m.default || m)
  },
  {
    name: indexmX5tBR6fmVMeta?.name ?? "areas-areaId-index-promo-codes",
    path: indexmX5tBR6fmVMeta?.path ?? "promo-codes",
    meta: indexmX5tBR6fmVMeta || {},
    alias: indexmX5tBR6fmVMeta?.alias || [],
    redirect: indexmX5tBR6fmVMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/promo-codes/index.vue").then(m => m.default || m)
  },
  {
    name: newYgfUWiTeZLMeta?.name ?? "areas-areaId-index-promo-codes-new",
    path: newYgfUWiTeZLMeta?.path ?? "promo-codes/new",
    meta: newYgfUWiTeZLMeta || {},
    alias: newYgfUWiTeZLMeta?.alias || [],
    redirect: newYgfUWiTeZLMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/[areaId]/index/promo-codes/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index4McHRRtIZHMeta?.name ?? "areas",
    path: index4McHRRtIZHMeta?.path ?? "/areas",
    meta: index4McHRRtIZHMeta || {},
    alias: index4McHRRtIZHMeta?.alias || [],
    redirect: index4McHRRtIZHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/index.vue").then(m => m.default || m)
  },
  {
    name: newFt4xE1ZrL8Meta?.name ?? "areas-new",
    path: newFt4xE1ZrL8Meta?.path ?? "/areas/new",
    meta: newFt4xE1ZrL8Meta || {},
    alias: newFt4xE1ZrL8Meta?.alias || [],
    redirect: newFt4xE1ZrL8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/areas/new.vue").then(m => m.default || m)
  },
  {
    name: indexbucWoApyliMeta?.name ?? "bookings-bookingId",
    path: indexbucWoApyliMeta?.path ?? "/bookings/:bookingId()",
    meta: indexbucWoApyliMeta || {},
    alias: indexbucWoApyliMeta?.alias || [],
    redirect: indexbucWoApyliMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91payoutId_93t9TJCuyMfJMeta?.name ?? "bookings-bookingId-index-account-payouts-payoutId",
    path: _91payoutId_93t9TJCuyMfJMeta?.path ?? "account-payouts/:payoutId()",
    meta: _91payoutId_93t9TJCuyMfJMeta || {},
    alias: _91payoutId_93t9TJCuyMfJMeta?.alias || [],
    redirect: _91payoutId_93t9TJCuyMfJMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/account-payouts/[payoutId].vue").then(m => m.default || m)
  },
  {
    name: indexd1bIEY6VTKMeta?.name ?? "bookings-bookingId-index-account-payouts",
    path: indexd1bIEY6VTKMeta?.path ?? "account-payouts",
    meta: indexd1bIEY6VTKMeta || {},
    alias: indexd1bIEY6VTKMeta?.alias || [],
    redirect: indexd1bIEY6VTKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/account-payouts/index.vue").then(m => m.default || m)
  },
  {
    name: newZmUpFFCfaHMeta?.name ?? "bookings-bookingId-index-account-payouts-new",
    path: newZmUpFFCfaHMeta?.path ?? "account-payouts/new",
    meta: newZmUpFFCfaHMeta || {},
    alias: newZmUpFFCfaHMeta?.alias || [],
    redirect: newZmUpFFCfaHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/account-payouts/new.vue").then(m => m.default || m)
  },
  {
    name: _91chargeId_93zksFS3oAHCMeta?.name ?? "bookings-bookingId-index-charges-chargeId",
    path: _91chargeId_93zksFS3oAHCMeta?.path ?? "charges/:chargeId()",
    meta: _91chargeId_93zksFS3oAHCMeta || {},
    alias: _91chargeId_93zksFS3oAHCMeta?.alias || [],
    redirect: _91chargeId_93zksFS3oAHCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/charges/[chargeId].vue").then(m => m.default || m)
  },
  {
    name: indexK1ZOEDeZfHMeta?.name ?? "bookings-bookingId-index-charges",
    path: indexK1ZOEDeZfHMeta?.path ?? "charges",
    meta: indexK1ZOEDeZfHMeta || {},
    alias: indexK1ZOEDeZfHMeta?.alias || [],
    redirect: indexK1ZOEDeZfHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/charges/index.vue").then(m => m.default || m)
  },
  {
    name: newp3B01qc1BrMeta?.name ?? "bookings-bookingId-index-charges-new",
    path: newp3B01qc1BrMeta?.path ?? "charges/new",
    meta: newp3B01qc1BrMeta || {},
    alias: newp3B01qc1BrMeta?.alias || [],
    redirect: newp3B01qc1BrMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/charges/new.vue").then(m => m.default || m)
  },
  {
    name: events7Bne9c2thOMeta?.name ?? "bookings-bookingId-index-events",
    path: events7Bne9c2thOMeta?.path ?? "events",
    meta: events7Bne9c2thOMeta || {},
    alias: events7Bne9c2thOMeta?.alias || [],
    redirect: events7Bne9c2thOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/events.vue").then(m => m.default || m)
  },
  {
    name: info6qapfWY3aSMeta?.name ?? "bookings-bookingId-index-info",
    path: info6qapfWY3aSMeta?.path ?? "info",
    meta: info6qapfWY3aSMeta || {},
    alias: info6qapfWY3aSMeta?.alias || [],
    redirect: info6qapfWY3aSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/info.vue").then(m => m.default || m)
  },
  {
    name: _91noteId_93Q4todAnz8FMeta?.name ?? "bookings-bookingId-index-notes-noteId",
    path: _91noteId_93Q4todAnz8FMeta?.path ?? "notes/:noteId()",
    meta: _91noteId_93Q4todAnz8FMeta || {},
    alias: _91noteId_93Q4todAnz8FMeta?.alias || [],
    redirect: _91noteId_93Q4todAnz8FMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/notes/[noteId].vue").then(m => m.default || m)
  },
  {
    name: indexKkTRSf6UP8Meta?.name ?? "bookings-bookingId-index-notes",
    path: indexKkTRSf6UP8Meta?.path ?? "notes",
    meta: indexKkTRSf6UP8Meta || {},
    alias: indexKkTRSf6UP8Meta?.alias || [],
    redirect: indexKkTRSf6UP8Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/notes/index.vue").then(m => m.default || m)
  },
  {
    name: newHpC9X6Crm4Meta?.name ?? "bookings-bookingId-index-notes-new",
    path: newHpC9X6Crm4Meta?.path ?? "notes/new",
    meta: newHpC9X6Crm4Meta || {},
    alias: newHpC9X6Crm4Meta?.alias || [],
    redirect: newHpC9X6Crm4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/notes/new.vue").then(m => m.default || m)
  },
  {
    name: _91paymentId_93Jjf0AnuPuXMeta?.name ?? "bookings-bookingId-index-payments-paymentId",
    path: _91paymentId_93Jjf0AnuPuXMeta?.path ?? "payments/:paymentId()",
    meta: _91paymentId_93Jjf0AnuPuXMeta || {},
    alias: _91paymentId_93Jjf0AnuPuXMeta?.alias || [],
    redirect: _91paymentId_93Jjf0AnuPuXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/payments/[paymentId].vue").then(m => m.default || m)
  },
  {
    name: indexq7e4wFl3wHMeta?.name ?? "bookings-bookingId-index-payments",
    path: indexq7e4wFl3wHMeta?.path ?? "payments",
    meta: indexq7e4wFl3wHMeta || {},
    alias: indexq7e4wFl3wHMeta?.alias || [],
    redirect: indexq7e4wFl3wHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/payments/index.vue").then(m => m.default || m)
  },
  {
    name: newgFTIDLytfDMeta?.name ?? "bookings-bookingId-index-payments-new",
    path: newgFTIDLytfDMeta?.path ?? "payments/new",
    meta: newgFTIDLytfDMeta || {},
    alias: newgFTIDLytfDMeta?.alias || [],
    redirect: newgFTIDLytfDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/payments/new.vue").then(m => m.default || m)
  },
  {
    name: _91payoutId_93ZiirvDWN79Meta?.name ?? "bookings-bookingId-index-provider-payouts-payoutId",
    path: _91payoutId_93ZiirvDWN79Meta?.path ?? "provider-payouts/:payoutId()",
    meta: _91payoutId_93ZiirvDWN79Meta || {},
    alias: _91payoutId_93ZiirvDWN79Meta?.alias || [],
    redirect: _91payoutId_93ZiirvDWN79Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/provider-payouts/[payoutId].vue").then(m => m.default || m)
  },
  {
    name: indexA243Phz5nCMeta?.name ?? "bookings-bookingId-index-provider-payouts",
    path: indexA243Phz5nCMeta?.path ?? "provider-payouts",
    meta: indexA243Phz5nCMeta || {},
    alias: indexA243Phz5nCMeta?.alias || [],
    redirect: indexA243Phz5nCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/provider-payouts/index.vue").then(m => m.default || m)
  },
  {
    name: newGzJQgLcx3rMeta?.name ?? "bookings-bookingId-index-provider-payouts-new",
    path: newGzJQgLcx3rMeta?.path ?? "provider-payouts/new",
    meta: newGzJQgLcx3rMeta || {},
    alias: newGzJQgLcx3rMeta?.alias || [],
    redirect: newGzJQgLcx3rMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/[bookingId]/index/provider-payouts/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexqVDJglidTwMeta?.name ?? "bookings",
    path: indexqVDJglidTwMeta?.path ?? "/bookings",
    meta: indexqVDJglidTwMeta || {},
    alias: indexqVDJglidTwMeta?.alias || [],
    redirect: indexqVDJglidTwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: newKVtEpjGbnOMeta?.name ?? "bookings-new",
    path: newKVtEpjGbnOMeta?.path ?? "/bookings/new",
    meta: newKVtEpjGbnOMeta || {},
    alias: newKVtEpjGbnOMeta?.alias || [],
    redirect: newKVtEpjGbnOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/bookings/new.vue").then(m => m.default || m)
  },
  {
    name: indexGlGZrlZGdXMeta?.name ?? "categories-categoryId",
    path: indexGlGZrlZGdXMeta?.path ?? "/categories/:categoryId()",
    meta: indexGlGZrlZGdXMeta || {},
    alias: indexGlGZrlZGdXMeta?.alias || [],
    redirect: indexGlGZrlZGdXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/categories/[categoryId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: infoPcoMcUvRjTMeta?.name ?? "categories-categoryId-index-info",
    path: infoPcoMcUvRjTMeta?.path ?? "info",
    meta: infoPcoMcUvRjTMeta || {},
    alias: infoPcoMcUvRjTMeta?.alias || [],
    redirect: infoPcoMcUvRjTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/categories/[categoryId]/index/info.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index6yDS2Nat0aMeta?.name ?? "categories",
    path: index6yDS2Nat0aMeta?.path ?? "/categories",
    meta: index6yDS2Nat0aMeta || {},
    alias: index6yDS2Nat0aMeta?.alias || [],
    redirect: index6yDS2Nat0aMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: newz6eM2CXxbUMeta?.name ?? "categories-new",
    path: newz6eM2CXxbUMeta?.path ?? "/categories/new",
    meta: newz6eM2CXxbUMeta || {},
    alias: newz6eM2CXxbUMeta?.alias || [],
    redirect: newz6eM2CXxbUMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/categories/new.vue").then(m => m.default || m)
  },
  {
    name: indexroQjwNrhD2Meta?.name ?? "charges",
    path: indexroQjwNrhD2Meta?.path ?? "/charges",
    meta: indexroQjwNrhD2Meta || {},
    alias: indexroQjwNrhD2Meta?.alias || [],
    redirect: indexroQjwNrhD2Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/charges/index.vue").then(m => m.default || m)
  },
  {
    name: indexc699YS4snzMeta?.name ?? "contexts-contextId",
    path: indexc699YS4snzMeta?.path ?? "/contexts/:contextId()",
    meta: indexc699YS4snzMeta || {},
    alias: indexc699YS4snzMeta?.alias || [],
    redirect: indexc699YS4snzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: account_45payouts13o8EzXFo6Meta?.name ?? "contexts-contextId-index-account-payouts",
    path: account_45payouts13o8EzXFo6Meta?.path ?? "account-payouts",
    meta: account_45payouts13o8EzXFo6Meta || {},
    alias: account_45payouts13o8EzXFo6Meta?.alias || [],
    redirect: account_45payouts13o8EzXFo6Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/account-payouts.vue").then(m => m.default || m)
  },
  {
    name: additional_45info0jwY0IqdWXMeta?.name ?? "contexts-contextId-index-additional-info",
    path: additional_45info0jwY0IqdWXMeta?.path ?? "additional-info",
    meta: additional_45info0jwY0IqdWXMeta || {},
    alias: additional_45info0jwY0IqdWXMeta?.alias || [],
    redirect: additional_45info0jwY0IqdWXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/additional-info.vue").then(m => m.default || m)
  },
  {
    name: bookings9GKtsUPFvQMeta?.name ?? "contexts-contextId-index-bookings",
    path: bookings9GKtsUPFvQMeta?.path ?? "bookings",
    meta: bookings9GKtsUPFvQMeta || {},
    alias: bookings9GKtsUPFvQMeta?.alias || [],
    redirect: bookings9GKtsUPFvQMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/bookings.vue").then(m => m.default || m)
  },
  {
    name: charges3n1C30ThmFMeta?.name ?? "contexts-contextId-index-charges",
    path: charges3n1C30ThmFMeta?.path ?? "charges",
    meta: charges3n1C30ThmFMeta || {},
    alias: charges3n1C30ThmFMeta?.alias || [],
    redirect: charges3n1C30ThmFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/charges.vue").then(m => m.default || m)
  },
  {
    name: infoVrPOUawKlwMeta?.name ?? "contexts-contextId-index-info",
    path: infoVrPOUawKlwMeta?.path ?? "info",
    meta: infoVrPOUawKlwMeta || {},
    alias: infoVrPOUawKlwMeta?.alias || [],
    redirect: infoVrPOUawKlwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/info.vue").then(m => m.default || m)
  },
  {
    name: invoicesXGfEzLkOHDMeta?.name ?? "contexts-contextId-index-invoices",
    path: invoicesXGfEzLkOHDMeta?.path ?? "invoices",
    meta: invoicesXGfEzLkOHDMeta || {},
    alias: invoicesXGfEzLkOHDMeta?.alias || [],
    redirect: invoicesXGfEzLkOHDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/invoices.vue").then(m => m.default || m)
  },
  {
    name: paymentscNDVodeJ4JMeta?.name ?? "contexts-contextId-index-payments",
    path: paymentscNDVodeJ4JMeta?.path ?? "payments",
    meta: paymentscNDVodeJ4JMeta || {},
    alias: paymentscNDVodeJ4JMeta?.alias || [],
    redirect: paymentscNDVodeJ4JMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/payments.vue").then(m => m.default || m)
  },
  {
    name: provider_45payoutscO87HGP7VMMeta?.name ?? "contexts-contextId-index-provider-payouts",
    path: provider_45payoutscO87HGP7VMMeta?.path ?? "provider-payouts",
    meta: provider_45payoutscO87HGP7VMMeta || {},
    alias: provider_45payoutscO87HGP7VMMeta?.alias || [],
    redirect: provider_45payoutscO87HGP7VMMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/provider-payouts.vue").then(m => m.default || m)
  },
  {
    name: transferseA2qvnKOfGMeta?.name ?? "contexts-contextId-index-transfers",
    path: transferseA2qvnKOfGMeta?.path ?? "transfers",
    meta: transferseA2qvnKOfGMeta || {},
    alias: transferseA2qvnKOfGMeta?.alias || [],
    redirect: transferseA2qvnKOfGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contexts/[contextId]/index/transfers.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login4dfr6kRqBoMeta?.name ?? "login",
    path: login4dfr6kRqBoMeta?.path ?? "/login",
    meta: login4dfr6kRqBoMeta || {},
    alias: login4dfr6kRqBoMeta?.alias || [],
    redirect: login4dfr6kRqBoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: indexD5WnwoNjuPMeta?.name ?? "modalities-modalityId",
    path: indexD5WnwoNjuPMeta?.path ?? "/modalities/:modalityId()",
    meta: indexD5WnwoNjuPMeta || {},
    alias: indexD5WnwoNjuPMeta?.alias || [],
    redirect: indexD5WnwoNjuPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/modalities/[modalityId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: infol239hkoaiXMeta?.name ?? "modalities-modalityId-index-info",
    path: infol239hkoaiXMeta?.path ?? "info",
    meta: infol239hkoaiXMeta || {},
    alias: infol239hkoaiXMeta?.alias || [],
    redirect: infol239hkoaiXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/modalities/[modalityId]/index/info.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexLjd8kOrXwqMeta?.name ?? "modalities",
    path: indexLjd8kOrXwqMeta?.path ?? "/modalities",
    meta: indexLjd8kOrXwqMeta || {},
    alias: indexLjd8kOrXwqMeta?.alias || [],
    redirect: indexLjd8kOrXwqMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/modalities/index.vue").then(m => m.default || m)
  },
  {
    name: newAw6r4JmKOIMeta?.name ?? "modalities-new",
    path: newAw6r4JmKOIMeta?.path ?? "/modalities/new",
    meta: newAw6r4JmKOIMeta || {},
    alias: newAw6r4JmKOIMeta?.alias || [],
    redirect: newAw6r4JmKOIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/modalities/new.vue").then(m => m.default || m)
  },
  {
    name: indexghywfYsx49Meta?.name ?? "payments",
    path: indexghywfYsx49Meta?.path ?? "/payments",
    meta: indexghywfYsx49Meta || {},
    alias: indexghywfYsx49Meta?.alias || [],
    redirect: indexghywfYsx49Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexeFiMOHGC5ZMeta?.name ?? "payouts",
    path: indexeFiMOHGC5ZMeta?.path ?? "/payouts",
    meta: indexeFiMOHGC5ZMeta || {},
    alias: indexeFiMOHGC5ZMeta?.alias || [],
    redirect: indexeFiMOHGC5ZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: indexSpiKnQKynbMeta?.name ?? "services-serviceId",
    path: indexSpiKnQKynbMeta?.path ?? "/services/:serviceId()",
    meta: indexSpiKnQKynbMeta || {},
    alias: indexSpiKnQKynbMeta?.alias || [],
    redirect: indexSpiKnQKynbMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: accountsTh8XRgFp3yMeta?.name ?? "services-serviceId-index-accounts",
    path: accountsTh8XRgFp3yMeta?.path ?? "accounts",
    meta: accountsTh8XRgFp3yMeta || {},
    alias: accountsTh8XRgFp3yMeta?.alias || [],
    redirect: accountsTh8XRgFp3yMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/accounts.vue").then(m => m.default || m)
  },
  {
    name: additional_45infoYWncVB1iSSMeta?.name ?? "services-serviceId-index-additional-info",
    path: additional_45infoYWncVB1iSSMeta?.path ?? "additional-info",
    meta: additional_45infoYWncVB1iSSMeta || {},
    alias: additional_45infoYWncVB1iSSMeta?.alias || [],
    redirect: additional_45infoYWncVB1iSSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/additional-info.vue").then(m => m.default || m)
  },
  {
    name: areasNVxbNTOWkvMeta?.name ?? "services-serviceId-index-areas",
    path: areasNVxbNTOWkvMeta?.path ?? "areas",
    meta: areasNVxbNTOWkvMeta || {},
    alias: areasNVxbNTOWkvMeta?.alias || [],
    redirect: areasNVxbNTOWkvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/areas.vue").then(m => m.default || m)
  },
  {
    name: imagesbZ6FRJXuDcMeta?.name ?? "services-serviceId-index-images",
    path: imagesbZ6FRJXuDcMeta?.path ?? "images",
    meta: imagesbZ6FRJXuDcMeta || {},
    alias: imagesbZ6FRJXuDcMeta?.alias || [],
    redirect: imagesbZ6FRJXuDcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/images.vue").then(m => m.default || m)
  },
  {
    name: infouADns46T5kMeta?.name ?? "services-serviceId-index-info",
    path: infouADns46T5kMeta?.path ?? "info",
    meta: infouADns46T5kMeta || {},
    alias: infouADns46T5kMeta?.alias || [],
    redirect: infouADns46T5kMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/info.vue").then(m => m.default || m)
  },
  {
    name: _91locationId_9369dpBSEzYCMeta?.name ?? "services-serviceId-index-locations-locationId",
    path: _91locationId_9369dpBSEzYCMeta?.path ?? "locations/:locationId()",
    meta: _91locationId_9369dpBSEzYCMeta || {},
    alias: _91locationId_9369dpBSEzYCMeta?.alias || [],
    redirect: _91locationId_9369dpBSEzYCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: indexsAzwi88pUiMeta?.name ?? "services-serviceId-index-locations",
    path: indexsAzwi88pUiMeta?.path ?? "locations",
    meta: indexsAzwi88pUiMeta || {},
    alias: indexsAzwi88pUiMeta?.alias || [],
    redirect: indexsAzwi88pUiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/locations/index.vue").then(m => m.default || m)
  },
  {
    name: newzVHgDo9tefMeta?.name ?? "services-serviceId-index-locations-new",
    path: newzVHgDo9tefMeta?.path ?? "locations/new",
    meta: newzVHgDo9tefMeta || {},
    alias: newzVHgDo9tefMeta?.alias || [],
    redirect: newzVHgDo9tefMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/locations/new.vue").then(m => m.default || m)
  },
  {
    name: skills9XpKnZhgPkMeta?.name ?? "services-serviceId-index-skills",
    path: skills9XpKnZhgPkMeta?.path ?? "skills",
    meta: skills9XpKnZhgPkMeta || {},
    alias: skills9XpKnZhgPkMeta?.alias || [],
    redirect: skills9XpKnZhgPkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/[serviceId]/index/skills.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexVrpVjnZSa3Meta?.name ?? "services",
    path: indexVrpVjnZSa3Meta?.path ?? "/services",
    meta: indexVrpVjnZSa3Meta || {},
    alias: indexVrpVjnZSa3Meta?.alias || [],
    redirect: indexVrpVjnZSa3Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: newA2PMd6BJ7MMeta?.name ?? "services-new",
    path: newA2PMd6BJ7MMeta?.path ?? "/services/new",
    meta: newA2PMd6BJ7MMeta || {},
    alias: newA2PMd6BJ7MMeta?.alias || [],
    redirect: newA2PMd6BJ7MMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/services/new.vue").then(m => m.default || m)
  },
  {
    name: index8vUQxPinJcMeta?.name ?? "skills",
    path: index8vUQxPinJcMeta?.path ?? "/skills",
    meta: index8vUQxPinJcMeta || {},
    alias: index8vUQxPinJcMeta?.alias || [],
    redirect: index8vUQxPinJcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/skills/index.vue").then(m => m.default || m)
  },
  {
    name: test_45base_45layer8YDqQuCUASMeta?.name ?? "test-base-layer",
    path: test_45base_45layer8YDqQuCUASMeta?.path ?? "/test-base-layer",
    meta: test_45base_45layer8YDqQuCUASMeta || {},
    alias: test_45base_45layer8YDqQuCUASMeta?.alias || [],
    redirect: test_45base_45layer8YDqQuCUASMeta?.redirect,
    component: () => import("/opt/buildhome/repo/node_modules/.c12/github_GoExpondDev_gx_VbOiz9Kg2G/pages/test-base-layer.vue").then(m => m.default || m)
  },
  {
    name: index9vYc50yRfeMeta?.name ?? "users-userId",
    path: index9vYc50yRfeMeta?.path ?? "/users/:userId()",
    meta: index9vYc50yRfeMeta || {},
    alias: index9vYc50yRfeMeta?.alias || [],
    redirect: index9vYc50yRfeMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index.vue").then(m => m.default || m),
    children: [
  {
    name: accountstSYvsBMx8dMeta?.name ?? "users-userId-index-accounts",
    path: accountstSYvsBMx8dMeta?.path ?? "accounts",
    meta: accountstSYvsBMx8dMeta || {},
    alias: accountstSYvsBMx8dMeta?.alias || [],
    redirect: accountstSYvsBMx8dMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/accounts.vue").then(m => m.default || m)
  },
  {
    name: indexKtvmlmxucPMeta?.name ?? "users-userId-index-bookings",
    path: indexKtvmlmxucPMeta?.path ?? "bookings",
    meta: indexKtvmlmxucPMeta || {},
    alias: indexKtvmlmxucPMeta?.alias || [],
    redirect: indexKtvmlmxucPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: news57jRetNSKMeta?.name ?? "users-userId-index-bookings-new",
    path: news57jRetNSKMeta?.path ?? "bookings/new",
    meta: news57jRetNSKMeta || {},
    alias: news57jRetNSKMeta?.alias || [],
    redirect: news57jRetNSKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/bookings/new.vue").then(m => m.default || m)
  },
  {
    name: indexwNlZW86lOPMeta?.name ?? "users-userId-index-credit-cards",
    path: indexwNlZW86lOPMeta?.path ?? "credit-cards",
    meta: indexwNlZW86lOPMeta || {},
    alias: indexwNlZW86lOPMeta?.alias || [],
    redirect: indexwNlZW86lOPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/credit-cards/index.vue").then(m => m.default || m)
  },
  {
    name: newIZFH8sD2weMeta?.name ?? "users-userId-index-credit-cards-new",
    path: newIZFH8sD2weMeta?.path ?? "credit-cards/new",
    meta: newIZFH8sD2weMeta || {},
    alias: newIZFH8sD2weMeta?.alias || [],
    redirect: newIZFH8sD2weMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/credit-cards/new.vue").then(m => m.default || m)
  },
  {
    name: infoWOHTNf4odgMeta?.name ?? "users-userId-index-info",
    path: infoWOHTNf4odgMeta?.path ?? "info",
    meta: infoWOHTNf4odgMeta || {},
    alias: infoWOHTNf4odgMeta?.alias || [],
    redirect: infoWOHTNf4odgMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/info.vue").then(m => m.default || m)
  },
  {
    name: _91locationId_93MRdOlGCQtHMeta?.name ?? "users-userId-index-locations-locationId",
    path: _91locationId_93MRdOlGCQtHMeta?.path ?? "locations/:locationId()",
    meta: _91locationId_93MRdOlGCQtHMeta || {},
    alias: _91locationId_93MRdOlGCQtHMeta?.alias || [],
    redirect: _91locationId_93MRdOlGCQtHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: indexJnc72xAc5EMeta?.name ?? "users-userId-index-locations",
    path: indexJnc72xAc5EMeta?.path ?? "locations",
    meta: indexJnc72xAc5EMeta || {},
    alias: indexJnc72xAc5EMeta?.alias || [],
    redirect: indexJnc72xAc5EMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/locations/index.vue").then(m => m.default || m)
  },
  {
    name: newx0Yuh9MFtPMeta?.name ?? "users-userId-index-locations-new",
    path: newx0Yuh9MFtPMeta?.path ?? "locations/new",
    meta: newx0Yuh9MFtPMeta || {},
    alias: newx0Yuh9MFtPMeta?.alias || [],
    redirect: newx0Yuh9MFtPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/locations/new.vue").then(m => m.default || m)
  },
  {
    name: paymentsPjPv5OlOXQMeta?.name ?? "users-userId-index-payments",
    path: paymentsPjPv5OlOXQMeta?.path ?? "payments",
    meta: paymentsPjPv5OlOXQMeta || {},
    alias: paymentsPjPv5OlOXQMeta?.alias || [],
    redirect: paymentsPjPv5OlOXQMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/payments.vue").then(m => m.default || m)
  },
  {
    name: _91payoutId_93XQluNUkPAIMeta?.name ?? "users-userId-index-payouts-payoutId",
    path: _91payoutId_93XQluNUkPAIMeta?.path ?? "payouts/:payoutId()",
    meta: _91payoutId_93XQluNUkPAIMeta || {},
    alias: _91payoutId_93XQluNUkPAIMeta?.alias || [],
    redirect: _91payoutId_93XQluNUkPAIMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/payouts/[payoutId].vue").then(m => m.default || m)
  },
  {
    name: indexd0NM9hTZ1PMeta?.name ?? "users-userId-index-payouts",
    path: indexd0NM9hTZ1PMeta?.path ?? "payouts",
    meta: indexd0NM9hTZ1PMeta || {},
    alias: indexd0NM9hTZ1PMeta?.alias || [],
    redirect: indexd0NM9hTZ1PMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: new300mPj5fATMeta?.name ?? "users-userId-index-payouts-new",
    path: new300mPj5fATMeta?.path ?? "payouts/new",
    meta: new300mPj5fATMeta || {},
    alias: new300mPj5fATMeta?.alias || [],
    redirect: new300mPj5fATMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/payouts/new.vue").then(m => m.default || m)
  },
  {
    name: providersTMNbEavGVSMeta?.name ?? "users-userId-index-providers",
    path: providersTMNbEavGVSMeta?.path ?? "providers",
    meta: providersTMNbEavGVSMeta || {},
    alias: providersTMNbEavGVSMeta?.alias || [],
    redirect: providersTMNbEavGVSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/providers.vue").then(m => m.default || m)
  },
  {
    name: skills247uGjBtKzMeta?.name ?? "users-userId-index-skills",
    path: skills247uGjBtKzMeta?.path ?? "skills",
    meta: skills247uGjBtKzMeta || {},
    alias: skills247uGjBtKzMeta?.alias || [],
    redirect: skills247uGjBtKzMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/skills.vue").then(m => m.default || m)
  },
  {
    name: transfersAFO7htne7MMeta?.name ?? "users-userId-index-transfers",
    path: transfersAFO7htne7MMeta?.path ?? "transfers",
    meta: transfersAFO7htne7MMeta || {},
    alias: transfersAFO7htne7MMeta?.alias || [],
    redirect: transfersAFO7htne7MMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/transfers.vue").then(m => m.default || m)
  },
  {
    name: zipcodes8r1z26taSDMeta?.name ?? "users-userId-index-zipcodes",
    path: zipcodes8r1z26taSDMeta?.path ?? "zipcodes",
    meta: zipcodes8r1z26taSDMeta || {},
    alias: zipcodes8r1z26taSDMeta?.alias || [],
    redirect: zipcodes8r1z26taSDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/[userId]/index/zipcodes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexxwOGCwGhjWMeta?.name ?? "users",
    path: indexxwOGCwGhjWMeta?.path ?? "/users",
    meta: indexxwOGCwGhjWMeta || {},
    alias: indexxwOGCwGhjWMeta?.alias || [],
    redirect: indexxwOGCwGhjWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91role_93EuOJDzRqbSMeta?.name ?? "users-new-role",
    path: _91role_93EuOJDzRqbSMeta?.path ?? "/users/new/:role()",
    meta: _91role_93EuOJDzRqbSMeta || {},
    alias: _91role_93EuOJDzRqbSMeta?.alias || [],
    redirect: _91role_93EuOJDzRqbSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users/new/[role].vue").then(m => m.default || m)
  }
]